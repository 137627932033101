var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import Vue from "vue";
import Component from "vue-class-component";
import { Layout, Dependencies } from "~/core/decorator";
import { PageService } from "~/utils/page.service";
import DataForm from "~/components/common/data-form.vue";
import DataBox from "~/components/common/data-box.vue";
import { namespace } from "vuex-class";
import LetterPrintPending from "~/components/assistant-manage/letter-print/letter-print-pending.vue";
import LetterPrintHandled from "~/components/assistant-manage/letter-print/letter-print-handled.vue";
import WaitPrint from "~/components/assistant-manage/letter-print/wait-print.vue";
import AlreadyPrint from "~/components/assistant-manage/letter-print/already-print.vue";
import PrincipalBatch from "~/components/common-business/principal-batch.vue";
import BatchApprove from "~/components/assistant-manage/batch-approve.vue";
import LetterBatchPrint from "~/components/assistant-manage/letter-print/letter-batch-print.vue";
var letterAccModule = namespace("letter-acc");
var letterPrint = /** @class */ (function (_super) {
    __extends(letterPrint, _super);
    function letterPrint() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.letterModel = {
            principalName: "",
            certificateNo: "",
            applyRealName: "",
            batchNumber: "",
            caseNumber: "",
            personalName: "",
            applyDate: []
        };
        _this.approvalCommitLoading = false;
        // 默认激活页面
        _this.activeName = "pending-info";
        _this.approvalRules = {
            result: [{ required: true, message: "请选择审批结果", trigger: "change" }]
        };
        _this.assistDataSet = null;
        _this.assistObj = {};
        _this.selectionList = [];
        _this.dialog = {
            printLetter: false,
            printPreview: false,
            batchApproveDialog: false,
            batchPrintletter: false,
        };
        _this.rules = {
            certificateNo: [{ min: 4, message: '请输入至少4位的身份证号', trigger: 'blur' }]
        };
        return _this;
    }
    letterPrint.prototype.handleResetForm = function () {
        this.letterModel.principalId = '';
    };
    letterPrint.prototype.validSectionList = function (value) {
        var component = this.$refs[value];
        if (component.selectionList.length === 0) {
            this.$message.info("请勾选列表中要审批的数据!");
            return;
        }
        this.selectionList = component.selectionList;
    };
    letterPrint.prototype.batchPrintClick = function (tabName) {
        this.validSectionList(tabName);
        this.dialog.batchPrintletter = true;
    };
    letterPrint.prototype.closeBatchPrintWindow = function (tabName) {
        this.dialog.batchPrintletter = false;
        var component = this.$refs[tabName];
        var databox = component.$refs.printDataBox;
        databox.clearSelection();
    };
    letterPrint.prototype.batchApproved = function (value) {
        this.validSectionList(value);
        if (this.selectionList.length === 0)
            return;
        this.dialog.batchApproveDialog = true;
    };
    letterPrint.prototype.deactivated = function () {
        for (var v in this.dialog) {
            this.dialog[v] = false;
        }
    };
    letterPrint.prototype.mounted = function () {
        this.refreshData();
    };
    //切换tab页面刷新对应的页面
    letterPrint.prototype.handleTabClick = function (tab, event) {
        this.activeName = tab.name;
        this.selectionList = [];
        this.resetSearchFelid();
        this.refreshData();
    };
    //刷新数据
    letterPrint.prototype.refreshData = function () {
        var tabdom = this.$refs[this.activeName];
        if (tabdom) {
            tabdom.refreshData();
        }
    };
    //reset搜索框条件
    letterPrint.prototype.resetSearchFelid = function () {
        this.letterModel = {
            principalName: "",
            certificateNo: "",
            applyRealName: "",
            batchNumber: "",
            personalName: "",
            applyDate: [],
        };
    };
    __decorate([
        Dependencies(PageService)
    ], letterPrint.prototype, "pageService", void 0);
    __decorate([
        letterAccModule.Mutation
    ], letterPrint.prototype, "updatePrintInfo", void 0);
    letterPrint = __decorate([
        Layout("workspace"),
        Component({
            components: {
                DataForm: DataForm,
                DataBox: DataBox,
                LetterPrintPending: LetterPrintPending,
                LetterPrintHandled: LetterPrintHandled,
                PrincipalBatch: PrincipalBatch,
                BatchApprove: BatchApprove,
                WaitPrint: WaitPrint,
                AlreadyPrint: AlreadyPrint,
                LetterBatchPrint: LetterBatchPrint
            }
        })
    ], letterPrint);
    return letterPrint;
}(Vue));
export default letterPrint;
